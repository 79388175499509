import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RegisterOAuth } from '../models/user';
import { AuthService } from '../shared-components/ng-login/auth/auth.service';
import { LoadingStore } from '../stores/loading.store';
import { ApiService } from './api.service';
import { DomainConfigService } from './domain-config.service';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {
  private authService = inject(AuthService);
  private apiService = inject(ApiService);
  private domainConfService = inject(DomainConfigService);
  private loading = inject(LoadingStore);

  private loginEuroinnovaOptions;

  loginResponse = new BehaviorSubject(false);

  constructor() {
    const customerConfig = this.domainConfService.getConfigForSubdomain(this.apiService.getDomain());

    /* eslint-disable @typescript-eslint/naming-convention */
    this.loginEuroinnovaOptions = {
      baseUrl: customerConfig.socialLoginConfig?.euroinnova?.baseUrl,
      client_id: customerConfig.socialLoginConfig?.euroinnova?.clientId,
      response_type: 'code',
      scope: 'user_read',
      redirect_uri: window.location.origin
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  handleAuthState(authorizationCode: string, language: string = ''): void {
    if (authorizationCode) {
      const parameters = new RegisterOAuth(
        '',
        authorizationCode,
        this.loginEuroinnovaOptions.client_id,
        language,
        this.loginEuroinnovaOptions.redirect_uri
      );

      this.authService.login(parameters).subscribe((response) => {
        this.loading.stop();
        this.loginResponse.next(!!response);
      });
    }
  }

  signin(lang = ''): void {
    const baseUrl =
      `${this.loginEuroinnovaOptions.baseUrl}?client_id=${this.loginEuroinnovaOptions.client_id}` +
      `&response_type=${this.loginEuroinnovaOptions.response_type}&` +
      `redirect_uri=${this.loginEuroinnovaOptions.redirect_uri}&scope=${this.loginEuroinnovaOptions.scope}`;

    const url = lang ? `${baseUrl}&lang=${lang}` : baseUrl;

    window.location.replace(url);
  }
}
